<template>
  <div class="game-container">
    <!-- <v-card class="game-page-card-menu hidden-md-and-up">
        <v-row no-gutters justify="space-around">
            <v-col v-for="gameType in storageGameProviderType" :key="gameType.type" cols="auto">
                <v-btn href class="pt-2 pb-1 px-0 page-navigator-item" :to="{name:
                        gameType.type == 'sb'
                          ? routeName.SPORTSBOOK
                          : gameType.type == 'rng'
                          ? routeName.SLOT
                          : gameType.type == 'ld'
                          ? routeName.CASINO
                          : gameType.type == 'fishing'
                          ? routeName.FISHING
                          : gameType.type == 'special'
                          ? routeName.SPECIAL
                          : gameType.type == 'table'
                          ? routeName.TABLE_GAMES
                          : gameType.type == 'lottery'
                          ? routeName.LOTTERY
                          : gameType.type == 'cricket'
                          ? routeName.CRICKET
                          : routeName.HOME
                    }" max-width="40px" height="auto" text :ripple="false" light depressed>
                    <v-row no-gutters>
                        <v-col cols="12" class="py-0 px-2 underline_bar text-center">
                            <v-divider class="game-menu-divider mx-2"></v-divider>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-1 text-center">
                            <label class="game-menu-title px-2 font-weight-bold">{{ $t(`label.${gameType.type}_short`) }}</label>
                        </v-col>
                    </v-row>
                </v-btn>
            </v-col>
        </v-row>
    </v-card> -->
    <div class="game-banner" v-if="providerSelected === 'crash'">
      <!-- <v-carousel class="banner" cycle delimiter-icon="panorama_fish_eye" :show-arrows="false" interval="10000" height="auto">
          <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
              <v-img :src="`${banner.desktop_banner}`" @click="callBannerAction(banner.action)" :contain="true" :aspect-ratio="3"></v-img>
          </v-carousel-item>
      </v-carousel> -->
      <v-img v-if="$vuetify.breakpoint.xsOnly" class="align-end" :aspect-ratio="1800 / 200" :src="`${mediaUrl}/game_banner/${providerSelected}_${language}.png`">
        <v-card-title class="font-weight-bold pa-0 white--text">
          <p class="mb-0 ml-3">
            <span class="text-capitalize">
              {{ $t(`label.${providerSelected}`) }}
            </span>
          </p>
        </v-card-title>
      </v-img>
      <v-img v-else class="align-end" :aspect-ratio="1800 / 200" :src="`${mediaUrl}/game_banner/${providerSelected}_${language}.png`">
        <v-card-title class="white--text font-weight-bold">
          <p class="ml-3">
            <span class="text-capitalize">
              {{ $t(`label.${providerSelected}`) }}
            </span>
          </p>
        </v-card-title>
      </v-img>
    </div>
    <div class="game-banner" v-else>
      <!-- <v-carousel class="banner" cycle delimiter-icon="panorama_fish_eye" :show-arrows="false" interval="10000" height="auto">
          <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
              <v-img :src="`${banner.desktop_banner}`" @click="callBannerAction(banner.action)" :contain="true" :aspect-ratio="3"></v-img>
          </v-carousel-item>
      </v-carousel> -->
      <v-img v-if="$vuetify.breakpoint.xsOnly" class="align-end" :aspect-ratio="1800 / 200" :src="`${mediaUrl}/game_banner/${providerSelected}.jpg`">
        <v-card-title class="font-weight-bold pa-0 white--text">
          <p class="mb-0 ml-3">
            <span class="text-capitalize">
              {{ $t(`label.${providerSelected}`) }}
            </span>
          </p>
        </v-card-title>
      </v-img>
      <v-img v-else class="align-end" :aspect-ratio="1800 / 200" :src="`${mediaUrl}/game_banner/${providerSelected}.jpg`">
        <v-card-title class="white--text font-weight-bold">
          <p class="ml-3">
            <span class="text-capitalize">
              {{ $t(`label.${providerSelected}`) }}
            </span>
          </p>
        </v-card-title>
      </v-img>
    </div>

    <div class="search-container">
      <div no-gutters class="search-wrapper">
        <v-combobox
          autocomplete="off"
          class="search_game pl-0"
          light
          hide-details
          dense
          solo
          filled
          :items="searchableGameList"
          :clearable="true"
          :label="$t('label.search_game')"
          :prepend-icon="'search'"
          @click:append="searchGame()"
          @click:clear="searchGame()"
          @change="searchGame()"
          item-text="name"
          v-model.trim="searchValue"
          return-object
        ></v-combobox>
      </div>
    </div>
    <div class="game-vendor-container" v-if="storageGameProviderType && storageGameProviderType.find(x => x.type == providerSelected)">
      <div class="game-vendor-wrapper">
        <div class="game-list-container d-flex justify-start">
          <div v-for="provider in storageGameProviderType.find(x => x.type == providerSelected).providers" class="vendor-list-item slot-product">
            <v-checkbox
              v-model="selectedProviderGroup[provider.providerCode]"
              @click="changeGroup"
              hide-details
              :label="stringFormat('{0}', $t(`gameVendor.${provider.providerCode}_${providerSelected}_short`))"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="games-card-container py-4">
      <div class="mx-auto games-card-wrapper px-2">
        <template v-for="(game, index) in gameList">
          <v-badge right overlap color="light" offset-x="30" offset-y="30" :key="game.provider + game.code + game.id">
            <template v-slot:badge class="slot-game-badge">
              <v-img class="badge-img" :src="checkCategory(game.category)"></v-img>
            </template>
            <div class="slot-game-card">
              <v-img class="slot-game-image-box" :src="game.imageCdn == null ? `${mediaUrl}/game_icons/en/${game.provider}/${game.code}_${game.id}.jpg` : `${game.imageCdn}`" />
              <div class="slot-game-button-container" :game-name="getGameName(game.locale)">
                <div class="slot-game-button-box" v-if="!isGameMaintenance(providerSelected, game.provider)">
                  <button-primary :action="() => launchGame(game, false)" :title="$t(`label.jomPlay`)" class="playBtn"></button-primary>
                  <v-btn v-if="game.isDemoAvailable" class="mt-3 buttonWhite white--text slot-game-demo-button" @click="launchGame(game, true)">
                    {{ $t(`label.tryDemoNow`) }}
                  </v-btn>
                </div>
                <div class="slot-game-button-box maintenance text-center" v-else>
                  <span class="mt-3 pa-2 primary--text under-maintenance-text">{{ $t(`label.underMaintenance`) }}</span>
                </div>
              </div>
            </div>
            <div class="slot-game-name-box">{{ getGameName(game.locale) }}</div>
          </v-badge>
          <!--    ghost markup to push last row items align to left , 'index >= 2' mean only display if more than one row(2 items min per row)     -->
          <v-badge id="autoFillEle1" v-if="index == gameList.length - 1 && index >= 0" right overlap color="light" offset-x="30" offset-y="30" style="height: 0 !important">
            <template v-slot:badge class="slot-game-badge"></template>
            <div class="slot-game-card" style="height: 0 !important">
              <v-img />
              <div :game-name="getGameName(game.locale)">
                <div v-if="!isGameMaintenance(providerSelected, game.provider)">
                  <v-btn v-if="game.isDemoAvailable" class="mt-3 buttonWhite white--text slot-game-demo-button" @click="launchGame(game, true)"></v-btn>
                </div>
              </div>
            </div>
          </v-badge>
          <v-badge id="autoFillEle2" v-if="index == gameList.length - 1 && index >= 0" right overlap color="light" offset-x="30" offset-y="30" style="height: 0 !important">
            <template v-slot:badge class="slot-game-badge"></template>
            <div class="slot-game-card" style="height: 0 !important">
              <v-img />
              <div :game-name="getGameName(game.locale)">
                <div v-if="!isGameMaintenance(providerSelected, game.provider)">
                  <v-btn v-if="game.isDemoAvailable" class="mt-3 buttonWhite white--text slot-game-demo-button" @click="launchGame(game, true)"></v-btn>
                </div>
              </div>
            </div>
          </v-badge>
          <v-badge id="autoFillEle3" v-if="index === gameList.length - 1 && index >= 0" right overlap color="light" offset-x="30" offset-y="30" style="height: 0 !important">
            <template v-slot:badge class="slot-game-badge"></template>
            <div class="slot-game-card" style="height: 0 !important">
              <v-img />
              <div :game-name="getGameName(game.locale)">
                <div v-if="!isGameMaintenance(providerSelected, game.provider)">
                  <v-btn v-if="game.isDemoAvailable" class="mt-3 buttonWhite white--text slot-game-demo-button" @click="launchGame(game, true)"></v-btn>
                </div>
              </div>
            </div>
          </v-badge>
          <v-badge id="autoFillEle4" v-if="index == gameList.length - 1 && index >= 0" right overlap color="light" offset-x="30" offset-y="30" style="height: 0 !important">
            <template v-slot:badge class="slot-game-badge"></template>
            <div class="slot-game-card" style="height: 0 !important">
              <v-img />
              <div :game-name="getGameName(game.locale)">
                <div v-if="!isGameMaintenance(providerSelected, game.provider)">
                  <v-btn v-if="game.isDemoAvailable" class="mt-3 buttonWhite white--text slot-game-demo-button" @click="launchGame(game, true)"></v-btn>
                </div>
              </div>
            </div>
          </v-badge>
        </template>
      </div>
      <p v-if="gameList.length <= 0" class="text-center text-capitalize mb-2">{{ $t(`label.noGameFound`) }}</p>
      <div class="text-center mb-6" v-if="pagination.totalPage > 1">
        <v-pagination
          class="game_pagination"
          v-model="pagination.currentPage"
          :length="pagination.totalPage"
          @input="changePage"
          :next-icon="'arrow_forward_ios'"
          :prev-icon="'arrow_back_ios'"
          :total-visible="pagination.paginationPageLimit"
        ></v-pagination>
      </div>

      <!-- <v-row class="game-vendor-list-container" no-gutters v-if="storageGameProviderType && storageGameProviderType.find(x => x.type == providerSelected)">
          <v-col cols="12" class="text-left">
              <div class="vendor-list-item slot-product elevation-0" style="padding-left:0;">
                  <v-btn height="auto" min-height="65" :class="searchCriteria.provider == '' || searchCriteria.provider == undefined || searchCriteria.provider == 'all' ? 'yellow--text' : ''" class="text-capitalize text-center subtitle-2 font-weight-bold my-0 vendor-list-item-button px-6" @click="goToVendor('all')">
                      <v-row>
                          <v-col cols="12" class="pa-0">
                              <v-col cols="12" class="py-0 text-center">
                                  <v-divider v-show="searchCriteria.provider == '' || searchCriteria.provider == undefined || searchCriteria.provider == 'all'" class="vendor-menu-divider"></v-divider>
                              </v-col>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                              {{ $t(`label.all`) }}
                          </v-col>
                      </v-row>
                  </v-btn>
              </div>
              <div class="vendor-list-item slot-product elevation-0" v-for="provider in storageGameProviderType.find(x => x.type == providerSelected).providers" :key="provider.providerCode">
                  <v-btn height="auto" min-height="65" @click="goToVendor(provider.providerCode)" :class="searchCriteria.provider == provider.providerCode ? 'yellow--text' : ''" class="text-capitalize text-center font-weight-bold my-0 vendor-list-item-button">
                      <v-row>
                          <v-col cols="12" class="pa-0">
                              <v-col cols="12" class="py-0 text-center">
                                  <v-divider v-show="searchCriteria.provider == provider.providerCode" class="vendor-menu-divider"></v-divider>
                              </v-col>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                              {{ stringFormat('{0}', $t(`gameVendor.${provider.providerCode}_${providerSelected}_long`)) }}
                          </v-col>
                      </v-row>
                  </v-btn>
              </div>
          </v-col>
      </v-row>
      <v-list class="slot-game-card-select px-2">
          <template v-for="game in gameList">
              <v-list-item :class="['px-0 slot-game-card-item text-center', $vuetify.breakpoint.smAndDown ? 'mb-2' : 'mb-5']" :key="game.provider + game.code + game.id">
                  <v-card class="elevation-0">
                      <v-badge left overlap color="light" offset-x="30" offset-y="30">
                          <template v-slot:badge>
                              <v-img :src="checkCategory(game.category)"></v-img>
                          </template>
                          <v-avatar :size="$vuetify.breakpoint.smAndDown ? $vuetify.breakpoint.xsOnly ? '100' : '120': '150'">
                              <v-img :src="game.imageCdn == null ?`${mediaUrl}/game_icons/en/${game.provider}/${game.code}_${game.id}.jpg`: `${game.imageCdn}`" />
                              <div class="slot-game-button-container">
                                  <div class="slot-game-button-container-box">
                                      <div class="slot-game-button-box d-flex justify-center" v-if="!isGameMaintenance(providerSelected, game.provider)">
                                          <v-btn icon class="slot-game-button" @click="launchGame(game, false)">
                                              <v-avatar :size="$vuetify.breakpoint.mdAndDown ? '48':'60'">
                                                  <img src="/static/svg/play_btn.svg" @mouseover="hoverPlayButton($event.currentTarget, true)" @mouseleave="hoverPlayButton($event.currentTarget, false)" alt />
                                              </v-avatar>
                                          </v-btn>
                                          <v-btn v-if="game.isDemoAvailable" icon class="slot-game-button" :class="$vuetify.breakpoint.xsOnly ? 'ml-4':'ml-8'" @click="launchGame(game, true)">
                                              <v-avatar :size="$vuetify.breakpoint.mdAndDown ? '48':'60'">
                                                  <img src="/static/svg/demo_btn.svg" @mouseover="hoverDemoButton($event.currentTarget, true)" @mouseleave="hoverDemoButton($event.currentTarget, false)" alt />
                                              </v-avatar>
                                          </v-btn>
                                      </div>
                                      <div class="slot-game-button-box maintenance text-center" v-else>
                                          <span class="mt-3 full-width font-weight-bold pa-2 primary--text under-maintenance-text">{{
                              $t(`label.underMaintenance`)
                            }}</span>
                                      </div>
                                  </div>
                              </div>
                          </v-avatar>
                      </v-badge>
                  </v-card>
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                          <v-card-text v-bind="attrs" v-on="on" class="pt-2 font-weight-bold slot-game-name-box text-center elevation-0">
                              {{ getGameName(game.locale) }}
                          </v-card-text>
                      </template>
                      <span>{{ getGameName(game.locale) }}</span>
                  </v-tooltip>
              </v-list-item>
          </template>
      </v-list>
      <p v-if="gameList.length <= 0" class="text-center text-capitalize mb-2">{{ $t(`label.noGameFound`) }}</p>
      <div class="text-center mb-6" v-if="pagination.totalPage > 1">
          <v-pagination class="game_pagination" v-model="pagination.currentPage" :length="pagination.totalPage" @input="changePage" :next-icon="'arrow_forward_ios'" :prev-icon="'arrow_back_ios'" :total-visible="pagination.paginationPageLimit"></v-pagination>
      </div> -->
    </div>
  </div>
</template>

<script>
import { locale, uiHelper } from '@/util'
import { CACHE_KEY, SHARED } from '@/constants/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import format from 'string-format'
import { GAME_TYPE_GAMES, GAME_CATEGORIES, GAME_PROVIDERS_TYPE_CONFIG } from '@/store/game.module'
import { SEMI_TRANSFER } from '@/store/transfer.module'
import _ from 'lodash'
import { FOOTER_SEO } from '@/store/lookup.module'

export default {
  name: 'TablePage',
  props: {
    providerSelected: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: instance => ({
    language: uiHelper.getLanguage(),
    gameNameList: [],
    searchValue: null,
    searchableGameList: [],
    searchedValue: '',
    isHotCls: false,
    isNewCls: false,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    gameFilterSheet: false,
    stringFormat: format,
    currentLanguage: uiHelper.getLanguage(),
    selectedGameName: '',
    selectedCategory: 'all',
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    },
    moreFilter: false,
    selectedProviderGroup: {},
    selectedCategoryGroup: {},
    gameProvider: '',
    gameName: '',
    filteredSlotGame: [],
    searchCriteria: {
      provider: '',
      pageNumber: 1,
      pageSize: 40,
      mobilePageSize: 20
    },
    gameList: [],
    categories: [],
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    storageRngGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + instance.providerSelected),
    routeName: ROUTE_NAME
  }),
  computed: {
    wallet() {
      return this.$store.state.member.walletBalance
    },
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    TableGames() {
      if (this.providerSelected == 'sb') {
        return this.$store.state.game.SportsbookGames
      } else if (this.providerSelected == 'ld') {
        return this.$store.state.game.CasinoGames
      } else if (this.providerSelected == 'lottery') {
        return this.$store.state.game.LotteryGames
      } else if (this.providerSelected == 'table') {
        return this.$store.state.game.TableGames
      } else if (this.providerSelected == 'cricket') {
        return this.$store.state.game.CricketGames
      } else if (this.providerSelected == 'crash') {
        return this.$store.state.game.CrashGames
      }
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    gameCategory() {
      return this.$store.state.game.categories
    },
    providerGroup() {
      if (this.$route.params.provider && this.$route.params.provider.length > 0) {
        for (let provider in this.$store.state.game.providerGroup) {
          if (this.$route.params.provider == 'rng') {
            this.$store.state.game.providerGroup[provider] = true
          } else if (this.$route.params.provider == provider) {
            this.$store.state.game.providerGroup[provider] = true
          } else {
            this.$store.state.game.providerGroup[provider] = false
          }
        }
      } else {
        for (let provider in this.$store.state.game.providerGroup) {
          this.$store.state.game.providerGroup[provider] = true
        }
      }
      this.selectedProviderGroup = this.$store.state.game.providerGroup
      return this.$store.state.game.providerGroup
    }
  },
  watch: {
    wallet() {
      return this.$store.state.member.walletBalance
    },
    $route() {
      this.getGame()
    },
    gameCategory() {
      let d = this.$store.state.game.categories
      this.categories.push({
        id: 0,
        name: 'all',
        icon: 'grid_on'
      })
      d.forEach(category => {
        let categoryName = category.name.toLowerCase()
        this.categories.push({
          id: category.id,
          name: categoryName,
          icon: categoryName == 'hot' ? 'whatshot' : categoryName == 'new' ? 'fiber_new' : ''
        })
      })
    },
    TableGames() {
      if (this.providerSelected == 'sb') {
        this.storageRngGames = this.$store.state.game.SportsbookGames
      } else if (this.providerSelected == 'ld') {
        this.storageRngGames = this.$store.state.game.CasinoGames
      } else if (this.providerSelected == 'lottery') {
        this.storageRngGames = this.$store.state.game.LotteryGames
      } else if (this.providerSelected == 'table') {
        this.storageRngGames = this.$store.state.game.TableGames
      } else if (this.providerSelected == 'cricket') {
        this.storageRngGames = this.$store.state.game.CricketGames
      } else if (this.providerSelected == 'crash') {
        this.storageRngGames = this.$store.state.game.CrashGames
      }
      this.pagination.currentPage = 1
      this.getFilteredGame(1)
    },
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
    },
    providerGroup() {
      this.selectedProviderGroup = this.providerGroup
      this.getFilteredGame(1)
    }
  },
  created() {
    this.getGameProviderTypeInfo()
    this.getGame()
    this.getGameCategories()
    this.metaTag()
    //this.setFooterSEO()
  },
  methods: {
    getGameProviderTypeInfo() {
      let providerTypeConfigObj = {
        currency: uiHelper.getCurrency(),
        platform: uiHelper.getPlatform()
      }
      this.$store.dispatch(`${GAME_PROVIDERS_TYPE_CONFIG}`, { providerTypeConfigObj })
    },
    setFooterSEO() {
      let pageObj = {
        currentRoute: this.$route.name
      }
      this.$store.dispatch(`${FOOTER_SEO}`, { pageObj })
    },
    metaTag() {
      if (this.providerSelected == 'sb') {
        uiHelper.setMetaTag('url', 'https://www.jaya9.app/sportsbook/')
        uiHelper.setTitle(`${locale.getMessage('meta.sportsbook_title')}`)
        uiHelper.setMetaTag('title', `${locale.getMessage('meta.sportsbook_title')}`)
        uiHelper.setMetaTag('description', `${locale.getMessage('meta.sportsbook_description')}`)
        uiHelper.setCanonical('https://www.jaya9.app/sportsbook/')
      } else if (this.providerSelected == 'ld') {
        uiHelper.setMetaTag('url', 'https://www.jaya9.app/casino/')
        uiHelper.setTitle(`${locale.getMessage('meta.casino_title')}`)
        uiHelper.setMetaTag('title', `${locale.getMessage('meta.casino_title')}`)
        uiHelper.setMetaTag('description', `${locale.getMessage('meta.casino_description')}`)
        uiHelper.setCanonical('https://www.jaya9.app/casino/')
      } else if (this.providerSelected == 'lottery') {
        uiHelper.setMetaTag('url', 'https://www.jaya9.app/lottery/')
        uiHelper.setTitle(`${locale.getMessage('meta.lottery_title')}`)
        uiHelper.setMetaTag('title', `${locale.getMessage('meta.lottery_title')}`)
        uiHelper.setMetaTag('description', `${locale.getMessage('meta.lottery_description')}`)
        uiHelper.setCanonical('https://www.jaya9.app/lottery/')
      } else if (this.providerSelected == 'table') {
        uiHelper.setMetaTag('url', 'https://www.jaya9.app/tablegames/')
        uiHelper.setTitle(`${locale.getMessage('meta.table_title')}`)
        uiHelper.setMetaTag('title', `${locale.getMessage('meta.table_title')}`)
        uiHelper.setMetaTag('description', `${locale.getMessage('meta.table_description')}`)
        uiHelper.setCanonical('https://www.jaya9.app/tablegames/')
      } else if (this.providerSelected == 'cricket') {
        uiHelper.setMetaTag('url', 'https://www.jaya9.app/cricket/')
        uiHelper.setTitle(`${locale.getMessage('meta.cricket_title')}`)
        uiHelper.setMetaTag('title', `${locale.getMessage('meta.cricket_title')}`)
        uiHelper.setMetaTag('description', `${locale.getMessage('meta.cricket_description')}`)
        uiHelper.setCanonical('https://www.jaya9.app/cricket/')
      } else if (this.providerSelected == 'crash') {
        uiHelper.setMetaTag('url', 'https://www.jaya9.app/crash/')
        uiHelper.setTitle(`${locale.getMessage('meta.crash_title')}`)
        uiHelper.setMetaTag('title', `${locale.getMessage('meta.crash_title')}`)
        uiHelper.setMetaTag('description', `${locale.getMessage('meta.crash_description')}`)
        uiHelper.setCanonical('https://www.jaya9.app/crash/')
      }
    },
    searchGame() {
      this.getFilteredGame(1)
    },
    isGameMaintenance(gameType, providerCode) {
      if (
        this.storageGameProviderType == null ||
        this.storageGameProviderType.find(x => x.type == gameType) == undefined ||
        this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode) == undefined
      ) {
        return false
      } else {
        return this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode).isMaintenance
      }
    },
    getGame() {
      this.searchCriteria.pageNumber = 1
      this.searchCriteria.provider = this.$route.params.provider != 'all' ? this.$route.params.provider : 'all'
      if (this.storageRngGames == null) {
        let Obj = {
          currency: uiHelper.getCurrency(),
          type: this.providerSelected,
          size: '',
          page: '',
          platform: uiHelper.getPlatform(),
          category: '',
          provider: '',
          mode: 'All'
        }
        this.$store.dispatch(`${GAME_TYPE_GAMES}`, {
          Obj
        })
      } else {
        // this.getFilteredGame(1)
      }
    },
    getGameCategories() {
      this.$store.dispatch(`${GAME_CATEGORIES}`)
    },
    getGameName(gameLocale) {
      try {
        return gameLocale.find(x => x.language == this.currentLanguage).name
      } catch (error) {
        return gameLocale.find(x => x.language == 'en').name
      }
    },
    getFilteredGame(targetPage) {
      this.searchCriteria.pageNumber = targetPage
      let d = this.storageRngGames
      if (this.searchValue != null && this.searchValue != '') {
        if (this.searchValue.name != '' && this.searchValue.name != null) {
          let filtered = this.searchValue.name
          let p = _.filter(d, function(obj) {
            return obj.locale[0].name.toLowerCase().indexOf(filtered.toLowerCase()) > -1
          })
          d = p
        }
      } else {
        // if (gameCetegory != 'all') {
        //     let p = this.storageRngGames.filter(function (el) {
        //         return el.category.filter(x => x.name.toLowerCase() == gameCetegory).length > 0
        //     })
        //     d = p
        // }
        // if (this.searchCriteria.provider != 'all' && this.searchCriteria.provider != '' && this.searchCriteria.provider != undefined) {
        //     let p = d.filter(x => x.provider == this.searchCriteria.provider)
        //     d = p
        // }

        const selectedProvider = []
        for (const [provider, isSelected] of Object.entries(this.selectedProviderGroup)) {
          if (isSelected) {
            selectedProvider.push(provider)
          }
        }
        d = this.storageRngGames.filter(x => selectedProvider.includes(x.provider))
      }
      d.forEach(x => {
        this.searchableGameList.push({
          provider: x.provider,
          id: x.id,
          name: x.locale[0].name,
          gamecode: x.code,
          type: x.type
        })
      })
      if (this.$vuetify.breakpoint.xsOnly) {
        this.gameList = d.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.mobilePageSize, this.searchCriteria.pageNumber * this.searchCriteria.mobilePageSize)
        this.pagination.totalPage = Math.ceil(d.length / this.searchCriteria.mobilePageSize)
      } else {
        this.gameList = d.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.pageSize, this.searchCriteria.pageNumber * this.searchCriteria.pageSize)
        this.pagination.totalPage = Math.ceil(d.length / this.searchCriteria.pageSize)
      }
    },
    changeGroup() {
      this.getFilteredGame(1)
    },
    changeGameCategory(gameCetegory) {
      this.gameFilterSheet = false
      this.selectedCategory = gameCetegory
      this.getFilteredGame(1)
      this.pagination.currentPage = 1
    },
    changePage(targetPage) {
      this.getFilteredGame(targetPage)
    },
    async directLaunchGame(gameInfo, isDemo) {
      let routeData = this.$router.resolve({
        name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
        params: {
          provider: gameInfo.provider,
          type: gameInfo.type
        },
        query: {
          id: gameInfo.id,
          code: gameInfo.code,
          demo: isDemo ? 1 : 0
        }
      })
      try {
        this.$ga.event('Game', `${isDemo ? 'Demo' : 'Real'} Play`, `${gameInfo.provider.toUpperCase()} - Slot - ${gameInfo.locale.find(x => x.language == 'en').name}`, gameInfo.id)
      } catch (err) {
        console.log(err)
      }
      if (this.providerSelected == 'sb' || this.providerSelected == 'cricket') {
        await uiHelper.openPopup(routeData.href, 'Game Play Lobby', 1440, 810)
        this.semiTransfer(gameInfo)
      } else {
        this.semiTransfer(gameInfo).then(() => {
          uiHelper.openPopup(routeData.href, 'Game Play Lobby', 1440, 810)
        })
      }
    },
    launchGame(gameInfo, isDemo) {
      if (!isDemo && !this.isLoggedIn) {
        this.$parent.openLoginDialog()
      } else {
        this.directLaunchGame(gameInfo, isDemo)
      }
    },
    semiTransfer(gameInfo) {
      let obj = {
        to_wallet: gameInfo.provider
      }
      return this.$store.dispatch(`${SEMI_TRANSFER}`, {
        obj
      })
    },
    goToVendor(providerCode) {
      this.gameFilterSheet = false
      // if (!this.isLoggedIn && this.isTransferWallet) {
      //     this.$parent.openLoginDialog()
      // } else {
      //
      // }
      if (this.providerSelected == 'sb') {
        this.$router.push({
          name: ROUTE_NAME.SPORTSBOOK_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (this.providerSelected == 'ld') {
        this.$router.push({
          name: ROUTE_NAME.CASINO_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (this.providerSelected == 'lottery') {
        this.$router.push({
          name: ROUTE_NAME.LOTTERY_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (this.providerSelected == 'table') {
        this.$router.push({
          name: ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (this.providerSelected == 'cricket') {
        this.$router.push({
          name: ROUTE_NAME.CRICKET_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (this.providerSelected == 'crash') {
        this.$router.push({
          name: ROUTE_NAME.CRASH_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (this.providerSelected == 'fishing') {
        this.$router.push({
          name: ROUTE_NAME.FISHING_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      }
    },
    checkCategory(gameCategory) {
      let isHot = _.findIndex(gameCategory, ['name', 'Hot']) >= 0
      let isNew = _.findIndex(gameCategory, ['name', 'New']) >= 0
      let newIcon = '/static/svg/game-icon-new.svg'
      let hotIcon = '/static/svg/game-icon-hot.svg'

      if (this.$vuetify.breakpoint.smAndDown) {
        newIcon = '/static/svg/game-icon-new-mobile.svg'
        hotIcon = '/static/svg/game-icon-hot-mobile.svg'
      }

      if (this.selectedCategory != 'hot' && this.selectedCategory != 'new') {
        if (isNew) {
          //return format('{0}/category_icons/New.svg', this.mediaUrl);
          return newIcon
        } else if (isHot) {
          //return format('{0}/category_icons/Hot.svg', this.mediaUrl);
          return hotIcon
        } else {
          return null
        }
      } else if (this.selectedCategory == 'hot' && isHot) {
        return hotIcon
      } else if (this.selectedCategory == 'new' && isNew) {
        return newIcon
      } else {
        return null
      }
    },
    hoverPlayButton(element, mouseOver) {
      if (mouseOver) {
        element.src = '/static/svg/play_btn_hover.svg'
      } else {
        element.src = '/static/svg/play_btn.svg'
      }
    },
    hoverDemoButton(element, mouseOver) {
      if (mouseOver) {
        element.src = '/static/svg/demo_btn_hover.svg'
      } else {
        element.src = '/static/svg/demo_btn.svg'
      }
    }
  }
}
</script>

<style lang="scss">
.search-container {
  background: #ffffff;

  .search-wrapper {
    max-width: 1240px;
    margin: auto;

    .search_game {
      display: flex;
      align-items: center;

      &.v-text-field--filled > .v-input__control > .v-input__slot {
        min-height: 35px;
        max-width: 240px;
        margin: 12px 0px;
      }
    }
  }
}

.game-vendor-container {
  white-space: nowrap;
  background-image: linear-gradient(0, #4f4abc, #1f2b97 100%);
  color: var(--v-text_color);
  text-transform: capitalize;

  .game-vendor-wrapper {
    position: relative;
    max-width: 1240px;
    margin: auto;

    .more-filter {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      padding: 12px;
      height: 40px;
      border-radius: 5px;
      background-image: linear-gradient(0, #000000, #1f2b97 100%);
      color: var(--v-secondary-base);
      cursor: pointer;
      transition: background-position 0.3s ease-in, text-shadow 0.3s ease-in-out 0.1s;
      background-size: 100% 400%;
      background-position: 0 0;
      box-shadow: 0px 3px 15px #111;
      z-index: 1;
    }

    .more-filter:hover {
      background-position: 0 25%;
      text-shadow: 0 1px 3px rgb(0 0 0 / 50%);
    }

    .vendor-list-item.slot-product {
      display: inline-block;
      margin-left: 12px;
      //min-width: 150px;

      .v-input {
        margin: 0px;
        padding: 8px;
      }

      .v-input--is-label-active {
        .mdi-checkbox-marked {
          color: var(--v-secondary-base) !important;
          caret-color: var(--v-secondary-base) !important;
        }
      }

      .v-btn {
        background: transparent;
      }
    }

    .game-provider {
      .v-item--active {
        .v-icon {
          color: var(--v-secondary-color) !important;
        }
      }
    }

    .game-list-container {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      background-image: transparent;
      color: var(--v-text_color);
    }

    .game-list-container:first-child {
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5), 0px 3px 10px rgba(0, 0, 0, 0.5);
    }
  }
}

.game-container {
  //background-color: #4e4e4e;
  background-color: var(--v-background-lighten1);

  .games-card-container {
    position: relative;
    max-width: 1240px;
    margin: auto;

    .games-card-wrapper {
      max-width: 1240px;
      margin: 12px auto;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      justify-content: space-evenly;
      align-items: center;

      .badge-img {
        border-radius: 50%;
      }

      .slot-game-badge {
        position: relative;
        height: 0;
        top: 0;
        left: 0;
        z-index: 1;
      }

      .v-badge__wrapper {
        .v-badge__badge {
          height: auto;
        }
      }

      .slot-game-card {
        position: relative;
        width: 232px;
        height: 160px;
        //background-color: rgba(20, 128, 94, 0.4);

        .slot-game-image-box {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }

        .slot-game-button-container {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: 0;

          .slot-game-button-box {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;

            //transform: scaleY(100);
            //transform: translateY(-100%);
            //transform-origin: bottom;
            transitislot-game-name-boxon: transform 1s ease-in;

            .slot-game-demo-button {
              background: linear-gradient(to right, #e32131 0%, #e63846 15%, #e63846 30%, #e32131 55%, #e32131 100%);
              background-position: 50% 0;
              background-size: 200%;
              box-shadow: 0 5px 10px 0 rgb(140 18 28 / 10%), inset 0 0 3px 0 #e63846;
              text-transform: capitalize;
              min-width: 120px;
            }
          }
        }

        &.isMaintenance {
          .slot-game-button-container {
            opacity: 1;
          }
        }

        .slot-game-button-container:hover {
          opacity: 1;
          //transform: translateY(100%);
          //transform: scaleY(1);
          background-color: rgba(0, 0, 0, 0.65);
          box-shadow: inset 0px 0px 1px 1px var(--v-primary-base);
        }
      }

      .slot-game-name-box {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 5px;
        width: 100%;
        min-height: 24px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        background-color: rgba(6, 12, 6, 0.5);
        box-shadow: 5px 5px 10px rgba(20, 128, 94, 0.7) inset;
        color: white;
        //background-image: linear-gradient(0, #14805e, #000000 100%);
        //background-image: url("/static/btn-light.png");
        //mask-image: image(url("/static/btn-light.png"), blue);
        //mask-image: url("/static/btn-light.png");
        //mask-repeat: no-repeat;
        //mask-position: center;
        //background-color: var(--v-primary-base);
        //background: url("/static/image/navigator/nav-light.png") no-repeat center bottom;
        //background-size: 100% 50%;
        //box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
}

@media (max-width: 599px) {
  .games-card-wrapper {
    display: flex !important;
    justify-content: space-evenly !important;

    .slot-game-card {
      flex: 1 1 160px !important;
      width: 160px !important;
      height: 115px !important;
    }

    .slot-game-name-box {
      background-color: var(--v-base-base);
    }
  }
}

.game_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 14px;
    }
  }
}

.vendor-list-item.slot-product {
  .v-label {
    color: white !important;
  }
}

.search_game .v-label {
  top: 8px !important;
}
</style>
